import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { graphql } from "gatsby";
import { Button } from "antd";

import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { ContentList } from "../../components/Lists";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import seoData from "../../components/SEO/data";
import { removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import { ALL_HOLY_PLACES, ALL_HOLY_PLACES_MAX_UPDATE } from "../../queries/queries.graphql";
import * as TargetBanner from "../../components/TargetBanner";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query HolyPlacesPage($slug: String!, $pagePath: String!)  {
		hasura {
			...AllHolyPlaces
			...Banners
			...PageSettings
		}
	}
`;

export default function HolyPlaces({ data, pageContext }) {
	const { theme } = useThemeContext();
	const { slug, title_full } = pageContext;
	const limit = 8;
	const maxUpdated = new Date(get(data, "hasura.v_content_holy_places_aggregate.aggregate.max.updated_at", new Date()));

	function makeData(data = []) {
		return data.map(({ title, path, image }) => {
			return {
				slug: path,
				image: get(image, "src"),
				title,
			};
		});
	}

	const [places, setPlaces] = useState(makeData(get(data, "hasura.v_content_holy_places", [])));
	const [banners] = useState(get(data, "hasura.banners", []));
	const [offset, setOffset] = useState(limit);
	const url = `${get(pageContext, "slug", "")}/holy-places`;
	const seo = get(data, "hasura.page_settings[0]", {});

	const holyPlacesOptions = {
		displayName: "holyPlacesQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(
		ALL_HOLY_PLACES_MAX_UPDATE,
		{
			variables: {
				slug,
			},
			...holyPlacesOptions,
		},
	);

	const [loadMore, { called: loadMoreCalled, loading: loadMoreLoading, error: loadMoreError, data: loadMoreData }] = useLazyQuery(
		ALL_HOLY_PLACES,
		{
			variables: {
				slug,
				limit,
				offset,
			},
			...holyPlacesOptions,
		});

	const [loadPlaces, { called, loading, error, data: placesData }] = useLazyQuery(
		ALL_HOLY_PLACES,
		{
			variables: {
				slug,
				limit,
				offset: 0,
			},
			...holyPlacesOptions,
			partialRefetch: true,
		});

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_content_holy_places_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadPlaces();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load HolyPlacesMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (placesData && !error) {
			setPlaces(makeData(get(placesData, "v_content_holy_places", places)));
			removePreloader(called);
		} else if (error) {
			console.error("Invalid load HolyPlacesData", { error });
			removePreloader();
		}
	}, [loading]);

	useEffect(() => {
		if (loadMoreCalled && !loadMoreError && !loadMoreLoading) {
			setPlaces([...places, ...makeData(get(loadMoreData, "v_content_holy_places", []))]);
			setOffset(get(loadMoreData, "v_content_holy_places", []).length < limit ? null : offset + limit);
		} else if (loadMoreError) {
			setOffset(null);
		}

		removePreloader(loadMoreCalled);
	}, [loadMoreLoading]);

	const breadcrumbsContext = {
		item: {
			slug,
			title_full,
		},
	};

	const bannerBlock = banners.filter(banner => get(banner, "params.enabled", false) && get(banner, "params.locations", "").match(/holy-places/)).map(banner => {
		return React.createElement(TargetBanner[get(banner, "params.type", "")] || TargetBanner.BannerImg, {
			...banner,
		});
	});

	return (
		<Pages
			entity={seo || get(seoData, "holy_places", {})}
			url={url}
		>
			{!!bannerBlock.length && <ContentBlock key={"banner"}>
				<div className={"banner__main-block d-noscript-none"}>{bannerBlock}</div>
			</ContentBlock>}
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Святые места"} pageContext={breadcrumbsContext} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Святые места"}>
						<ContentList
							items={places}
							className={"col-12 col-md-3"}
							style={{
								width: "100%",
								height: "400px",
								objectFit: "cover",
							}} />
					</TextBlock>
					<div className="row justify-content-center mb-5">
						<Button
							type={"primary"}
							disabled={loadMoreLoading || !offset}
							loading={loadMoreLoading}
							onClick={() => loadMore({
								variables: {
									offset,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={title_full} />
			</div>
		</Pages>
	);
}

HolyPlaces.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

HolyPlaces.defaultProps = {
	data: {},
	pageContext: {
		slug: "",
		title_full: "",
	},
};
